.border-none[data-v-4ad992ec]:before {
  display: none;
}
.border-none[data-v-4ad992ec]:after {
  display: none;
}
.flex-fix[data-v-4ad992ec] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-4ad992ec]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-4ad992ec] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-4ad992ec] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-4ad992ec] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-4ad992ec] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-4ad992ec] {
  *zoom: 1;
}
.clear-fix[data-v-4ad992ec]:before,
.clear-fix[data-v-4ad992ec]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.pwd-success[data-v-4ad992ec] {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: 0.7rem 0.74rem;
  font-size: 0.36rem;
  color: #333;
}
.pwd-success .success-svg[data-v-4ad992ec] {
  width: 2.2rem !important;
  height: 2.2rem !important;
  margin-bottom: 0.3rem;
}
.pwd-success .btn-box[data-v-4ad992ec] {
  margin-top: 0.64rem;
  width: 100%;
}
.pwd-success .btn-box .detail-btn[data-v-4ad992ec],
.pwd-success .btn-box .setting-btn[data-v-4ad992ec] {
  width: 2.62rem;
  height: 0.68rem;
  line-height: 0.68rem;
  border-radius: 0.44rem;
  text-align: center;
}
.pwd-success .btn-box .detail-btn[data-v-4ad992ec] {
  font-size: 0.32rem;
  color: var(--main-color);
  border: 0.02rem solid var(--main-color);
}
.pwd-success .btn-box .setting-btn[data-v-4ad992ec] {
  font-size: 0.32rem;
  color: #666;
  border: 0.02rem solid #666;
}
